<!--
 * @Author: 张贤元 wb_zhangxianyuan@tcl.com
 * @Date: 2022-10-28 15:13:05
 * @LastEditors: 秦龙(004894)
 * @LastEditTime: 2023-10-11 17:44:15
 * @FilePath: /i18n-saas-iot-frontend/src/views/operation-manage/mobile-upgrade/index.vue
 * @Description: 移动端升级
-->
<template>
  <div class="mobile-wrap">
    <el-form
      ref="form"
      size="small"
      class="search-pannel"
      label-position="left"
      :model="searchForm"
      @submit.native.prevent
    >
      <el-form-item label="应用系统：">
        <Select clearable placeholder="请选择应用系统" v-model="searchForm.systemCode" :list="systemList"></Select>
      </el-form-item>
      <el-form-item label="升级应用：">
        <Select clearable placeholder="请选择应用" v-model="searchForm.upgradeApplyCode" :list="upgradeList"></Select>
      </el-form-item>
      <el-form-item label="版本名称：">
        <el-input placeholder="请输入版本名称" v-model="searchForm.versionName"></el-input>
      </el-form-item>
      <el-form-item label="版本编码：">
        <el-input placeholder="请输入版本编码" v-model="searchForm.versionNo"></el-input>
      </el-form-item>
      <el-form-item label="创建时间：">
        <el-date-picker
          type="datetimerange"
          placeholder="选择日期"
          ange-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          value-format="yyyy-MM-dd HH:mm:ss"
          v-model="createTimeList"
          @change="monitorTime($event, 'create')"
        >
        </el-date-picker>
      </el-form-item>
      <el-form-item label="生效时间：">
        <el-date-picker
          type="datetimerange"
          placeholder="选择日期"
          ange-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          value-format="yyyy-MM-dd HH:mm:ss"
          v-model="validTimeList"
          @change="monitorTime($event, 'valid')"
        >
        </el-date-picker>
      </el-form-item>
      <el-form-item>
        <el-button type="success" size="small" native-type="submit" @click="onSearch">搜索</el-button>
        <el-button size="small" @click="onReset('form')">重置</el-button>
      </el-form-item>
    </el-form>
    <el-row>
      <el-button type="success" size="small" @click="newlyAdded">新增</el-button>
    </el-row>
    <Table
      :loading="tableLoading"
      :header-cell-style="{ background: '#F7F8FA ' }"
      :columns="columns"
      :data="tableData"
      :total="pageParams.total"
      :page="pageParams.pageNum"
      :limit="pageParams.pageSize"
      :hidden="!tableData.length"
      @pagination="updatePage"
    >
      <template #forceFlag="{ originScope }">
        {{ originScope.row.forceFlag === 1 ? '强更新' : '弱更新' }}
      </template>
    </Table>

    <!-- 新增升级 -->
    <AddUpgradeDlg
      :upgradeVisible.sync="upgradeVisible"
      :upgradeApplyCodeList="upgradeList"
      :systemCodeList="systemList"
    ></AddUpgradeDlg>
  </div>
</template>

<script>
import Table from '@/components/TablePage'
import Select from '@/components/Select'
import AddUpgradeDlg from './components/AddUpgradeDlg.vue'
import { getMobilePageTaskList } from '@/api/modules/operation'
import { getVendorList } from '@/api/modules/device'
export default {
  // 移动端升级
  name: 'mobileUpgrade',
  components: {
    Table,
    Select,
    AddUpgradeDlg
  },
  data() {
    return {
      systemList: [],
      upgradeList: [],
      searchForm: {
        systemCode: '', // 应用系统code
        upgradeApplyCode: '', // 升级应用code
        versionName: '', // 版本名称
        versionNo: '', // 版本编号
        createStartTime: '', // 开始创建时间
        createEndTime: '', // 结束创建时间
        validStartTime: '', // 开始生效时间
        validEndTime: '' // 结束生效时间
      },
      createTimeList: [],
      validTimeList: [],
      pageParams: {
        pageNum: 1,
        pageSize: 10,
        total: 1
      },
      tableData: [],
      columns: [
        { label: '升级应用', prop: 'upgradeApplyName' },
        { label: '版本名称', prop: 'versionName' },
        { label: '应用系統', prop: 'systemName' },
        { label: '版本编码', prop: 'versionNo' },
        { label: '升级包URL', prop: 'url' },
        { label: '升级包MD5', prop: 'md5' },
        { label: '创建时间', prop: 'createSysTm' },
        { label: '生效时间', prop: 'startTime' },
        { label: '创建人', prop: 'createUserName' },
        { label: '更新方式', prop: 'forceFlag' }
      ],
      tableLoading: false,
      upgradeVisible: false,
      cStyle: { width: '280px' }
    }
  },
  created() {
    this.onSearch(true)
    this.getSelecltDate()
  },
  methods: {
    // 获取参数
    getParams(flag) {
      const getParam = { ...this.searchForm }
      getParam.pageNum = flag ? 1 : this.pageParams.pageNum
      getParam.pageSize = this.pageParams.pageSize
      return getParam
    },
    onSearch(flag) {
      const data = {
        ...this.getParams(flag)
      }
      this.tableLoading = true
      getMobilePageTaskList(data)
        .then((res) => {
          const { records, pageNum, pageSize, total = 0 } = res.data
          this.tableData = records || []
          this.pageParams = { pageNum, pageSize, total }
        })
        .finally(() => {
          this.tableLoading = false
        })
    },
    getSelecltDate() {
      this.getVendor('upgradeList', 'mobileUpgradeApplication')
      this.getVendor('systemList', 'systemApplication')
    },
    // 获取下拉列表
    getVendor(fn, type) {
      getVendorList({ pageNum: 1, pageSize: 9999, entryVariable: type }).then((res) => {
        const { records } = res.data || []
        this[fn] = records || []
        this[fn].forEach((item) => {
          item.value = item.itemCode
          item.label = item.itemValue
        })
      })
    },
    onReset(formName) {
      this.createTimeList = []
      this.validTimeList = []
      this.searchForm = {
        systemCode: '',
        upgradeApplyCode: '',
        versionName: '',
        versionNo: '',
        createStartTime: '',
        createEndTime: '',
        validStartTime: '',
        validEndTime: ''
      }
    },
    // 监听时间变化
    monitorTime(val, type) {
      const [start, end] = val || []
      if (type === 'create') {
        this.searchForm.createStartTime = start
        this.searchForm.createEndTime = end
        this.onSearch()
      } else if (type === 'valid') {
        this.searchForm.validStartTime = start
        this.searchForm.validEndTime = end
        this.onSearch()
      }
    },
    // 新增
    newlyAdded() {
      this.upgradeVisible = true
    },
    // 更新分页
    updatePage(val) {
      if (val) {
        this.pageParams.pageNum = val.page
        this.pageParams.pageSize = val.limit
        this.onSearch()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.mobile-wrap {
  padding: 24px;
}
.red {
  color: red;
}
.default-color {
  color: #c8c9cc;
  margin-left: 5px;
  font-size: 18px;
}
</style>
