<!--
 * @Author: 张贤元 wb_zhangxianyuan@tcl.com
 * @Date: 2022-11-02 14:12:25
 * @LastEditors: 秦龙(004894)
 * @LastEditTime: 2023-10-12 09:41:58
 * @FilePath: /i18n-saas-iot-frontend/src/views/operation-manage/mobile-upgrade/components/AddUpgradeDlg.vue
 * @Description: 新增升级
-->
<template>
  <div>
    <Dialog width="920px" title="新增升级" :visible="upgradeVisible" @close="close">
      <div class="upgrade-dialog-wrap">
        <el-form
          size="small"
          ref="ruleForm"
          class="search-pannel-custom"
          label-width="110px"
          label-position="left"
          :rules="rules"
          :model="upgradeForm"
          @submit.native.prevent
        >
          <el-form-item label="升级应用：" prop="upgradeApplyCode">
            <Select
              clearable
              placeholder="请选择应用"
              v-model="upgradeForm.upgradeApplyCode"
              :list="upgradeApplyCodeList"
              @change="handlePublicSel($event, 'upgradeApplyCode')"
            ></Select>
          </el-form-item>
          <el-form-item label="版本名称：" prop="versionName">
            <el-input
              placeholder="请输入版本名称"
              oninput="if (value.length>30) value=value.slice(0,30)"
              v-model="upgradeForm.versionName"
            ></el-input>
          </el-form-item>
          <el-form-item label="应用系统：" prop="systemCode">
            <Select
              clearable
              placeholder="请选择应用系统"
              v-model="upgradeForm.systemCode"
              :list="systemCodeList"
              @change="handlePublicSel($event, 'systemCode')"
            ></Select>
          </el-form-item>
          <el-form-item label="版本编码：" prop="versionNo">
            <el-input placeholder="请输入版本编码" v-model.number="upgradeForm.versionNo"></el-input>
          </el-form-item>
          <!-- 萨尔瓦多快递员App -->
          <template v-if="['006'].includes(upgradeForm.upgradeApplyCode)">
            <el-form-item label="升级包URL：" prop="url">
              <el-input placeholder="请输入升级包URL" v-model="upgradeForm.url"></el-input>
            </el-form-item>
            <el-form-item label="升级包MD5：" prop="md5">
              <el-input placeholder="请输入升级包MD5" v-model="upgradeForm.md5"></el-input>
            </el-form-item>
          </template>
          <el-form-item label="是否强制更新：" prop="forceFlag" label-width="115px">
            <el-radio-group v-model="upgradeForm.forceFlag">
              <el-radio :label="2">否</el-radio>
              <el-radio :label="1">是</el-radio>
            </el-radio-group>
          </el-form-item>
          <!-- 版本描述 -->
          <el-form-item label="版本描述：">
            <el-table border :data="tabDataList" style="width: 100%">
              <el-table-column label="语言" width="180">
                <template slot-scope="scope">
                  <el-select
                    placeholder="请选择语言"
                    v-model="scope.row.langCode"
                    :disabled="scope.row.isWrite"
                    @change="handleLang($event, scope.$index)"
                  >
                    <el-option
                      v-for="item in langList"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                      :disabled="item.disabled"
                    >
                    </el-option>
                  </el-select>
                </template>
              </el-table-column>
              <el-table-column label="更新描述" width="400">
                <template slot-scope="scope">
                  <el-input
                    type="textarea"
                    placeholder="请输入更新描述"
                    v-if="!scope.row.isWrite"
                    v-model="scope.row.upgradeDesc"
                  ></el-input>
                  <span v-else>{{ scope.row.upgradeDesc }}</span>
                </template>
              </el-table-column>
              <el-table-column label="操作">
                <template slot-scope="scope">
                  <div v-if="scope.row.isWrite">
                    <i class="el-icon-minus" @click="handleReduce(scope.$index, scope.row)"></i>
                    <i class="el-icon-plus" @click="handleIncrease(scope.$index, scope.row)"></i>
                  </div>
                  <el-button v-else type="text" @click="confirm(scope.$index, scope.row)">确定</el-button>
                </template>
              </el-table-column>
            </el-table>
          </el-form-item>
          <el-form-item label="生效时间：" prop="immediateEffectFlag">
            <el-radio-group v-model="upgradeForm.immediateEffectFlag">
              <el-radio :label="1">立即生效</el-radio>
              <el-radio :label="2">定时发布</el-radio>
              <el-date-picker
                v-if="upgradeForm.immediateEffectFlag === 2"
                type="datetime"
                placeholder="选择日期"
                value-format="yyyy-MM-dd HH:mm:ss"
                v-model="upgradeForm.startTimeStr"
              >
              </el-date-picker>
            </el-radio-group>
            <div style="color: #999999; font-size: 14px">*升级说明：添加版本前请确保各应用市场已是最新版本</div>
          </el-form-item>
        </el-form>
        <div style="text-align: right">
          <el-button size="small" @click="() => $emit('update:upgradeVisible', false)">取 消</el-button>
          <el-button type="success" size="small" native-type="submit" @click="newUpgrades">确 定</el-button>
        </div>
      </div>
    </Dialog>
  </div>
</template>

<script>
import Select from '@/components/Select'
import Dialog from '@/components/Dialog'
import { getVendorList } from '@/api/modules/device'
import { addMobileUpgrade } from '@/api/modules/operation'
export default {
  components: {
    Select,
    Dialog
  },
  props: {
    upgradeVisible: {
      type: Boolean,
      default: false
    },
    upgradeApplyCodeList: {
      type: Array,
      default: () => []
    },
    systemCodeList: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    rules() {
      var ruleVersionNo = (rule, value, callback) => {
        if (!Number.isInteger(value)) {
          callback(new Error('版本编号必须为数字'))
        } else {
          callback()
        }
      }
      return {
        upgradeApplyCode: [{ required: true, message: '请选择升级应用', trigger: ['blur', 'change'] }],
        versionName: [
          { required: true, message: '请输入版本名称', trigger: 'blur' },
          { max: 30, message: '版本名称最长可支持输入30字', trigger: 'blur' }
        ],
        systemCode: [{ required: true, message: '请选择应用系统', trigger: ['blur', 'change'] }],
        versionNo: [
          { required: true, message: '请输入版本编号', trigger: 'blur' },
          { validator: ruleVersionNo, trigger: 'blur' }
        ],
        url: [
          { required: true, message: '请输入升级包URL', trigger: 'blur' },
          { type: 'url', message: '请输入正确的URL地址', trigger: 'blur' }
        ],
        md5: [
          { required: true, message: '请输入升级包MD5', trigger: 'blur' },
          { pattern: /^[a-f0-9]{32}/, message: '请输入正确的MD5', trigger: 'blur' }
        ],
        forceFlag: [{ required: true, message: '请选择是否强制更新', trigger: 'blur' }],
        immediateEffectFlag: [{ required: true, message: '请选择生效时间', trigger: 'blur' }]
      }
    }
  },
  data() {
    return {
      upgradeForm: {
        versionNo: '', // 版本编号
        versionName: '', // 版本名称
        startTimeStr: '', //  预设生效时间 如果是立即生效，则不传
        upgradeApplyCode: '', // 升级应用code
        upgradeApplyName: '', //  Hive Box Courier
        forceFlag: 2, //  是否是强制更新 1-是 2-否
        systemCode: '', // 应用系统code 1-iOS 2-Android
        systemName: '', // 应用系统名称
        mobileUpgradeLangConfigList: [], // 语言描述集合 传入langConfig从数据字典获取
        immediateEffectFlag: 1, // 1-立即生效 2-不立即生效
        url: '', // 升级包URL
        md5: ''
      },
      tabDataList: [
        {
          langName: '', // （语言名称）
          upgradeDesc: '', // 描述
          langCode: '', // 语言code
          isWrite: false
        }
      ],
      langList: []
    }
  },
  mounted() {
    this.getVendor('langList', 'langConfig')
  },
  methods: {
    // 获取下拉列表
    getVendor(fn, type) {
      getVendorList({ pageNum: 1, pageSize: 9999, entryVariable: type }).then((res) => {
        const { records } = res.data || []
        this[fn] = records || []
        this[fn].forEach((item) => {
          item.value = item.itemCode
          item.label = item.itemValue
        })
      })
    },
    // 新增升级
    newUpgrades() {
      const tabDataList = this.tabDataList.map(
        (item) => item.langCode && { langCode: item.langCode, langName: item.langName, upgradeDesc: item.upgradeDesc }
      )
      const { url, md5, ...other } = this.upgradeForm
      const paramsList = {
        ...other,
        mobileUpgradeLangConfigList: tabDataList.filter((item) => item.upgradeDesc),
        // 萨尔瓦多快递员App
        ...(['006'].includes(other.upgradeApplyCode) && {
          url,
          md5
        }),
      }

      if (paramsList.immediateEffectFlag === 1) {
        delete paramsList.startTimeStr
      } else if (!paramsList.mobileUpgradeLangConfigList) {
        delete paramsList.mobileUpgradeLangConfigList
      }

      if (paramsList.immediateEffectFlag === 2 && !paramsList.startTimeStr) {
        this.$message.warning('请添加定时发布时间')
        return
      }
      this.$refs.ruleForm.validate((valid) => {
        if (!valid) return
        addMobileUpgrade(paramsList).then((res) => {
          if (res.code === '000000000') {
            this.$message.success(res.msg)
            this.$emit('update:upgradeVisible', false)
            this.$parent.onSearch()
          } else {
            this.$message.warning(res.msg)
          }
        })
      })
    },
    /**
     * @description: 删除选中数据
     */
    handleReduce(index, row) {
      if (this.tabDataList.length === 1) {
        // this.$message.warning('描述语言不能少于一条数据')
        this.tabDataList = [
          {
            langName: '',
            upgradeDesc: '',
            langCode: '',
            isWrite: false
          }
        ]
        this.langList.forEach((item) => (item.disabled = false))
      } else {
        this.tabDataList.splice(index, 1)
        // 判断数据成功添加，减少时候往数据里面添加被删除数据
        if (row.isWrite) {
          this.langList.forEach((item) => {
            if ((item.itemCode || item.value) === row.langCode) {
              item.disabled = false
            }
          })
        }
      }
    },
    /**
     * @description: 添加单行数据
     */
    handleIncrease() {
      const langFlag = this.langList.every((item) => item.disabled)
      // 限制可配置信息不能超过语言最大长度
      if (langFlag || this.tabDataList.length >= this.langList.length) {
        this.$message.warning('已无语言配置项可配置')
      } else {
        this.tabDataList.push({
          langName: '',
          langCode: '',
          isWrite: false
        })
      }
    },
    /**
     * @description: 添加数据
     */
    confirm(index, row) {
      const tabDataList = this.tabDataList
      if (!tabDataList[index].langCode) {
        this.$message({
          type: 'warning',
          message: '请添加语言'
        })
        return false
      }
      if (!tabDataList[index].upgradeDesc) {
        this.$message({
          type: 'warning',
          message: '请添加描述信息'
        })
        return false
      }
      this.tabDataList[index].isWrite = true
      this.langList.forEach((item) => {
        if ((item.itemCode || item.value) === row.langCode) {
          item.disabled = true
        }
      })
      return ''
    },
    /**
     * @description: 处理语言名称
     * @param {*} code 语言code
     * @param {*} index 对应下标
     */
    handleLang(code, index) {
      this.langList.forEach((item) => {
        if (item.itemCode === code) {
          this.tabDataList[index].langName = item.itemValue
        }
      })
    },
    /**
     * @description: 处理应用&系统下拉框名称
     * @param {*} code 语言code
     * @param {*} type 类型
     */
    handlePublicSel(code, type) {
      if (type === 'upgradeApplyCode') {
        this.upgradeApplyCodeList.forEach((item) => {
          if (item.itemCode === code) {
            this.upgradeForm.upgradeApplyName = item.itemValue
          }
        })
      }
      if (type === 'systemCode') {
        this.systemCodeList.forEach((item) => {
          if (item.itemCode === code) {
            this.upgradeForm.systemName = item.itemValue
          }
        })
      }
    },
    /**
     * @description: 关闭弹窗&重置表单数据
     */
    close() {
      this.$refs.ruleForm.resetFields()
      this.tabDataList = [
        {
          langName: '',
          upgradeDesc: '',
          langCode: '',
          isWrite: false
        }
      ]
      this.upgradeForm = {
        versionNo: '',
        versionName: '',
        startTimeStr: '',
        upgradeApplyCode: '',
        upgradeApplyName: '',
        forceFlag: 2,
        systemCode: '',
        systemName: '',
        mobileUpgradeLangConfigList: [],
        immediateEffectFlag: 1,
        url: '',
        md5: ''
      }
      // 重置语言列表禁用状态
      this.langList.forEach((item) => {
        item.disabled = false
      })
      this.$emit('update:upgradeVisible', false)
    }
  }
}
</script>

<style lang="scss" scoped>
.search-pannel-custom {
  display: grid;
  grid-template-columns: repeat(1, auto-fill);
  grid-row-gap: 20px;
  padding-right: 28px;
}
.el-icon-minus,
.el-icon-plus {
  font-size: 24px;
}
.el-icon-plus {
  margin-left: 20px;
}
</style>

<style>
.el-dialog__body {
  padding: 15px 24px 24px 24px;
}
</style>
